import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

const BlogPage = ({ data, pageContext }) => {
	const fluidBanner = data.allFile.edges[0].node.childImageSharp.fluid;
	const { t } = useTranslation();

	return (
		<Layout>
			<SEO title="Blog" />
			{/* Banner */}
			<BackgroundImage Tag="div" fluid={fluidBanner}>
				<div className="container text-white h-400px">
					<div className="md:w-4/5 lg:w-2/3 xl:w-1/2 px-4 highlight">
						<h1 className="pt-32 text-4xl leading-tight font-bold">
							<span>
								<Trans>News and Stories</Trans>
							</span>
						</h1>
						<p className="text-lg mt-8">
							<Trans i18nKey="Read more about us">
								Read more about us. What is happening in the office, what we are doing outside of the
								office, what we have achieved during work and more.
							</Trans>
						</p>
					</div>
				</div>
			</BackgroundImage>
			{/* Blog-Posts */}
			<div className="py-4 text-brand-grayt">
				{data.allMarkdownRemark.edges.map((post, i) => (
					<div
						key={post.node.id}
						className={`${i % 2 === 0 ? "bg-white" : "bg-brand-gray4"} blog-article p-4`}
					>
						<div className="container">
							<div className="text-3xl hover:text-brand-green mb-6">
								<Link
									to={`/blog/${post.node.frontmatter.date.toString("YYYY-MM-DD").replace(/-/g, "/")}${
										post.node.frontmatter.slug
									}`}
								>
									<h1 className="uppercase">{post.node.frontmatter.title}</h1>
								</Link>
							</div>
							<div dangerouslySetInnerHTML={{ __html: post.node.html }} className="text-lg" />
						</div>
						<div className="clearfix"></div>
					</div>
				))}
				<div className="flex mt-5 mb-8 m-auto justify-center">
					{/* previousPageLink and nextPageLink were added by the plugin */}
					{pageContext.previousPagePath ? (
						<Link
							to={pageContext.previousPagePath}
							className="text-xl font-bold text-brand-gray2 hover:text-brand-green uppercase"
						>
							&#10229; Previous Page
						</Link>
					) : (
						""
					)}
					{pageContext.previousPagePath && pageContext.nextPagePath ? (
						<div className="text-xl font-bold text-brand-gray2 mx-2">|</div>
					) : (
						""
					)}
					{pageContext.nextPagePath ? (
						<Link
							to={pageContext.nextPagePath}
							className="text-xl font-bold text-brand-gray2 hover:text-brand-green uppercase"
						>
							Next Page &#10230;
						</Link>
					) : (
						""
					)}
				</div>
			</div>
		</Layout>
	);
};

export const pageQuery = graphql`
	query BlogPageQuery($skip: Int!, $limit: Int!) {
		allMarkdownRemark(limit: $limit, skip: $skip, sort: { fields: frontmatter___date, order: DESC }) {
			edges {
				node {
					id
					frontmatter {
						slug
						title
						date(formatString: "YYYY-MM-DD")
					}
					html
				}
			}
		}
		allFile(filter: { relativePath: { in: ["banner/blog.jpg"] } }) {
			edges {
				node {
					relativePath
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid
							...GatsbyImageSharpFluidLimitPresentationSize
						}
					}
				}
			}
		}
	}
`;

export default BlogPage;
